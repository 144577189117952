<template>
  <div class="view">
    <!-- <img class="view-bg" src="../../../public/images/Group 43.png" alt=""> -->

    <!-- 背景装饰图片 -->
    <div class="backgroundBox1">
      <img class="" src="../../../public/images/bg1.png" alt="" />
      <img src="../../../public/images/bg2.png" alt="" />
    </div>
    <img class="Vector1" src="../../../public/images/Vector 1.png" alt="" />
    <img class="numSell-bg" src="../../../public/images/Group 26.png" alt="" />
    <img class="bg3" src="../../../public/images/bg3.png" alt="" />

    <!-- 置顶按钮 -->
    <img @click="toTop()" src="../../../public/images/top.png" class="toTop"  alt="" />

    <!-- 菜单栏 -->
    <div class="home-top">
      <img src="../../../public/images/TE.png" alt="" />

      <div class="home-top-right">
        <div class="menu">
          <el-menu class="el-menu-demo" mode="horizontal">
            <el-menu-item index="1">首頁</el-menu-item>
            <el-menu-item @click="skipDownload()" index="2">下載與教程</el-menu-item>
            <el-menu-item @click="skipIntroduce()" index="3">软件介绍</el-menu-item>
            <el-menu-item @click="skipScenario()" index="4">使用場景</el-menu-item>
            <el-menu-item @click="skipNumSell()" index="5">靓号出售</el-menu-item>
            <el-menu-item @click="skipWel()" index="6">联系我们</el-menu-item>
          </el-menu>
        </div>

        <el-dropdown class="langSelect">
          <span class="el-dropdown-link"> 語言 </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <router-link class="link" style="color: #fff" to="/">中文</router-link>
            </el-dropdown-item>
            <el-dropdown-item>繁体</el-dropdown-item>
            <el-dropdown-item>
              <router-link class="link" style="color: #fff" to="/E">English</router-link>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="home-log">
        <img src="../../../public/images/top-logo-1.png" alt="" />
        <img src="../../../public/images/top-logo-2.png" alt="" />
      </div>
    </div>

    <!-- 标语栏 -->
    <img class="top-title" src="../../../public/images//Group 75@2x.png" />

    <ul class="slogan">
      <li>個性靚號 與眾不同 不可替代</li>
      <li>生意靠廣告 廣告靠靚號</li>
      <li>豹子靚號 私人專屬 彰顯尊貴</li>
      <li>錢包便捷多簽 資金絕對安全</li>
    </ul>

    <!-- 下载与教程 -->
    <div class="download">
      <h2 class="title downloadSkip">軟件 <span>下載與教程</span></h2>

      <div class="download-bg2">使用教程</div>

      <div class="download-content">
        <div class="download-content-left">
          <p class="download-content-1">
            本軟件只適用於windows 7以上版本的系統，
            靚號生成功能屬收費功能，下載後免費試用12
            小時，激活碼識別機器碼，一個激活碼只適用
            一台電腦，更換電腦時可以聯繫客服解綁激活 碼，重新綁定。
            Tron錢包多簽功能屬長期免費。
          </p>
          <div class="downloadFoot">
            <button @click="downloadARJ()" class="download-button">下載</button>
            <p class="downLink">下載地址：
              <a href="https://tetoken.vip/down/TE靓号v1.1.8.rar" download="TE靓号v1.1.8.rar"
                target="_blank">https://tetoken.vip/down/TE靓号v1.1.8.rar</a>
            </p>
          </div>
        </div>
      </div>

      <div class="download-video v-bg" v-if="play1" ><img @click="playPart1" src="../../../public/images/play.png" alt=""> </div>
      <video v-else class="download-video" autoplay src="https://tetoken.vip/使用教程.mp4" type="video/mp4" controls="controls"
        control="control" loop="-1"></video>
    </div>

    <!-- 软件介绍 -->
    <div class="introduce">
      <h2 class="title introduceSkip">軟件 <span>介紹</span></h2>
      <img class="introduce-bg1" src="../../../public/images/Group 45.png" alt="" />
      <div class="introduce-bg2"></div>
      <img class="introduce-bg3" src="../../../public/images/introduce-bg2.png" alt="" />

      <div class="introduce-content">
        <div class="introduce-content-left">
          <h3>Tron和ETH錢包地址靚號生成</h3>
          <p>
            本軟件可用於自動生成波場和以太坊的錢包地址，
            並從中篩選出適合需要的靚號和個性地址。無需多
            開、自適應本地電腦的運算能力；可篩選豹子尾數
            如:8888；可篩選個性尾數 如:SSiJ；可前後端 一起篩選，配置時用*隔開
            如:TG*8888;運算速度 受本地電腦的配置影響，也因篩選的位數有關，一
            般前後總的4-6位較快，7位以上可能要等數天。
          </p>
          <h3>Tron錢包多簽、轉賬功能</h3>
          <p>
            加密貨幣網絡上的標準交易可稱為單簽名交易，因為它們只
            需要一個數字簽名即可完成交易。而多重簽名是每個權限可
            以對應多個私鑰，要求交易簽名在執行之前必須達到定制的 權重。
            TE的TRON的多重簽名簡化了操作流程，用戶在創建
            一個多重簽名權限組時，可將地址添加至該權限組，軟件自
            動為每個地址設置權重，並為該權限組設置閾值。在進行相
            關權限的交易簽名時，自動為該權限組添加的地址配置簽名
            權重之和達到閾值，則可以執行交易。操作簡單易用，絕對 安全可靠！
          </p>
        </div>

        <div class="introduce-content-right">
          <h3>軟件介紹視頻</h3>
          <div class="introduce-video v-bg" v-if="play2" ><img @click="playPart2" src="../../../public/images/play.png" alt=""> </div>
          <video class="introduce-video" v-else autoplay src="https://tetoken.vip/软件介绍.mp4" type="video/mp4" controls="controls"
            control="control" loop="-1"></video>
          <p>地址：</p>
          <p>TJuzziZto3MSt3Ytfk7m66ciZDgVHQ888888</p>
          <p>私鑰：</p>
          <p>
            9943cb2c379da286911203462aa9813fd07d607c000815ad30487b07285e8fc9
          </p>
          <p>轉走了錢是您的！</p>
        </div>
      </div>
    </div>

    <!-- 使用场景 -->
    <div class="scenario">
      <h2 class="title scenarioSkip">使用場景</h2>

      <div class="scenario-content">
        <div class="scenario-content-1">
          <div>
            <h3>豹子靚號 私人專屬 彰顯尊貴， 用於平台收款錢包，彰顯實力！</h3>
            <p>如：</p>
            <p>TTUmoFHmtqKAXKHomRQRCdN4sexT<span>EEEEEE</span></p>
          </div>
          <img src="../../../public/images/Group 28.png" alt="" />
        </div>

        <div class="scenario-content-1">
          <div>
            <h3>個性靚號 與眾不同 不可替代， 用於平台多個錢包，整齊規範！</h3>
            <p>如：</p>
            <p><span>TN5</span>8z1T82jB2VTaAXuBMMPLw1MmAr<span>F2tVV</span></p>
            <p><span>TN5</span>sXaQ6WuNGDS276XTt6VtEbZeAm<span>F2tVV</span></p>
          </div>
          <img src="../../../public/images/Group 29.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 靓号出售 -->
    <div class="numSell">
      <h2 class="title numSellSkip"><span>軟件 · 靚號 </span> 出售</h2>
      <div class="numSearch">
        <div class="numSearch-bg"></div>
        <input class="search" v-model="numSearch" placeholder="搜索靚號" @keyup="enterSearch"  type="text" />
        <i class="el-icon-search search-button" @click="searchNum()"></i>
      </div>

      <div class="numSell-content">
        <div class="numSell-content-left">
          <h3>TRC20靚號錢包地址價格</h3>
          <p>四位：<span>4.9U</span> 如：TGemo……TEE8888</p>
          <p>五位：<span>20.9U</span> 如：TGemo……TE88888</p>
          <p>六位：<span>29.9U</span> 如：TGemo……T888888</p>
          <h3 class="numSell-title-2">軟件價格</h3>
          <p>零售：T-E錢包生成軟件單價： <span>288U</span></p>
          <p>團購：一次性購買2-5個，單價： <span>198U</span></p>
          <p>代理：一次性購買5個以上，單價： <span>128U</span> （可寄存）</p>
        </div>

        <div class="numSell-content-left">
          <h3>ERC20靚號錢包地址價格</h3>
          <p>五位：<span>4.9U</span> 如：0xB0f……De288888</p>
          <p>六位：<span>20.9U</span> 如：0xB0f……De888888</p>
          <p>七位：<span>29.9U</span> 如：0xB0f……D8888888</p>
          <img src="../../../public/images/image 1.png" alt="" />
        </div>
        <p class="collectionCode">
          收款钱包：TTUmoFHmtqKAXKHomRQRCdN4sexTEEEEEE
          <el-tooltip class="item" effect="dark" content="单击复制" placement="top">
            <img @click="copy()" src="../../../public/images/copy.png"  alt="" />
          </el-tooltip>
        </p>
      </div>
    </div>

    <!-- 安全操作警告 -->
    <div class="safe">
      <img src="../../../public/images/Group 20.png" alt="" />

      <div class="safe-title">
        <img src="../../../public/images/Frame.png" alt="" />
        <h3>安全警示 使用軟件應注意的安全操作警示</h3>
      </div>
      <p>
        本軟件的Tron多簽技術採用多層加密技術，多簽地址私鑰多
        層加密存儲在本地，離開了本地電腦和缺少您設置的密碼是
        無法解密私鑰，保證錢包私鑰的安全性。建議使用多籤的TE
        軟件採用乾淨的windows10以上的正版系統，防止盜版系統
        存在木馬，防止有剪切板挾持等情況，複製地址和私鑰時，
        請認真確認是否正確。本軟件只有多簽轉賬功能，沒有授權
        合約的功能，自然不會被利用於合約授權盜取錢包資產。
      </p>

      <p>
        參考教程：<a href="https://t.me/Wallet_Safety" target="_blank">https://t.me/Wallet_Safety</a>
      </p>
    </div>

    <!-- 页脚 -->
    <div class="foot">
      <div class="foot-contact">
        <img src="../../../public/images/foot.png" alt="" />
        <div>
          <h3>歡迎聯繫</h3>
          <h3>
            可用 <a href="https://t.me/TEservice/" target="_blank">Telegram</a>、
            <a href="https://songge8804@gmail.com/" target="_blank">Facebook</a>、
            <a href="https://api.whatsapp.com/send?phone=+14452987497&text=Hello/" target="_blank">Washapp</a>、 <a
              href="https://songge8804@gmail.com/" target="_blank">邮箱</a> 等聯繫方式
          </h3>
        </div>
      </div>
      <div class="foot-more">
        <img src="../../../public/images/TE.png" alt="" />
        <p>更多详情 请聯繫 <span>TE工作室</span></p>
      </div>
    </div>

    <!-- 检索靓号弹窗 -->
    <el-dialog class="searchMo" :visible.sync="searchVisible" top="10vh" :before-close="handleClose" :show-close="false">
      <div class="searchDialog">
        <h3 v-if="isTRC">TRC20 靚號地址</h3>
        <h3 v-else>ERC20 靚號地址</h3>
        <img @click="handleClose" src="../../../public/images/close.png" alt="" />
        <div class="searchData">
          <template v-for="item in data">
            <a v-if="isTRC"  :key="item.id" :href="'https://tronscan.io/#/address/' + item.address" target="_blank">{{ item.address }}</a>
            <a v-else  :key="item.id + 'E'" :href="'https://cn.etherscan.com/address/' + item.address" target="_blank">{{ item.address }}</a>
          </template>
        </div>
        <el-pagination :page-size="12" class="tableFoot" layout="prev, pager, next" :total="total"
          @current-change="handleCurrentChange">
        </el-pagination>

        <button v-if="isTRC" @click="() => getErcData({ address: this.numSearch })" class="change">切換為ERC20 靚號地址</button>
        <button v-else @click="() => getData({ address: this.numSearch })" class="change">切換為TRC20 靚號地址</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  data() {
    return {
      numSearch: "",
      searchVisible: false,
      data: [],
      currentPage: 1, //当前页
      total: 0,
      isTRC:true,
      play1:true,
      play2:true,
    };
  },
  computed: {
    searchData() {
      return this.data.slice((this.currentPage - 1) * 12 + 1, this.currentPage * 12 + 1)
    }
  },
  methods: {
    handleSelect(key, keyPath) { },
    onSubmit() {
      console.log("submit!");
    },
    downloadARJ() {
      window.open("https://tetoken.vip/down/TE靓号v1.1.8.rar");
    },
    copy() {
      let url = "TTUmoFHmtqKAXKHomRQRCdN4sexTEEEEEE";
      //新建一个文本框
      let oInput = document.createElement("input");
      //赋值给文本框
      oInput.value = url;
      document.body.appendChild(oInput);
      // 选择对象;
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      //复制完成删除掉输入框
      oInput.remove();
      this.$message({
        message: "复制成功",
        type: "success",
        duration: 1500
      });
    },
    skipDownload() {
      document.querySelector(".download").scrollIntoView(true);
    },
    skipIntroduce() {
      document.querySelector(".introduceSkip").scrollIntoView(true);
    },
    skipScenario() {
      document.querySelector(".scenarioSkip").scrollIntoView(true);
    },
    skipNumSell() {
      document.querySelector(".numSellSkip").scrollIntoView(true);
    },
    skipWel() {
      document.querySelector(".foot").scrollIntoView(true);
    },
    toTop() {
      document.querySelector(".home-top").scrollIntoView(true);
    },
    searchNum() {
      if (this.numSearch == "") {
        this.$message("请先输入靚號");
      } else {
        this.getData({ address: this.numSearch,size:12,current:this.currentPage })
      }
    },
    // 输入框回车事件
    enterSearch(e) {
      if (e.keyCode == 13) {
        this.searchNum();
      }
    },
    handleClose() {
      this.searchVisible = false;
      this.numSearch = "";
      this.data = [];
      this.currentPage = 1
    },
    playPart1(){
      this.play1 = false
    },
    playPart2(){
      this.play2 = false
    },
    // 翻页操作
    handleCurrentChange(val) {
      this.currentPage = val;
      if (this.isTRC) {
        this.getData({ address: this.numSearch,size:12,current:this.currentPage })
      }else{
        this.getErcData({ address: this.numSearch,pageSize:12,currentPage:this.currentPage })
      }
    },
    // 获取搜索靓号数据
    async getData(data) {
      // console.log(data);
      const res = await this.$api.numberApi.post(data)
      if (res.data.records.length == 0) {
        this.isTRC = true
        this.searchVisible = true;
        this.$message.error('TRC20靓号未查询到相关靓号，请确认后重新查询')
      } else {
        // console.log(res);
        this.isTRC = true
        this.total = res.data.total
        this.data = res.data.records;
        this.searchVisible = true;
      }

    },
    // 获取搜索靓号数据
    async getErcData(data) {
      const res = await this.$api.numberApi.postE(data)
      if (res.records.length == 0) {
        this.$message.error('ERC20靓号未查询到相关靓号，请确认后重新查询')
      } else {
        this.total = res.total;
        this.data = res.records;
        this.searchVisible = true;
        this.isTRC = false
      }

    },
    // 切换ERC20列表
    changeERC(){
      this.currentPage = 1
      this.getErcData({ address: this.numSearch,pageSize:12,currentPage:this.currentPage })
    },
    // 切换TRC20列表
    changeTRC(){
      this.currentPage = 1
      this.getData({ address: this.numSearch,size:12,current:this.currentPage })
    },
  },
};
</script>


<style lang="less" scoped >
@import "../../style/home.less";
@import "../../style/home-t.less";
</style>    


<style  >

.el-pager li,
.el-pagination button:disabled,
.el-pagination .btn-next,
.el-pagination .btn-prev{
  background: #5958d8 !important;
  margin-right: .5rem !important;
  color: #fff !important;
  border-radius: .3125rem !important;
  min-width: 2.25rem !important;
  height: 2.25rem !important;
  font-size: 1.25rem !important;
  line-height: 2.25rem !important;
}

.el-pager li.active{
  color: #409EFF !important;
}

.el-dialog{
  background: none !important;
  box-shadow: none !important;
}
@media (max-width: 480px) {
  .el-menu-demo .el-menu-item{
    font-size: 7px !important;
    padding: 0.5rem !important;
}

.home-top .langSelect .el-dropdown-link  {
  font-size: 7px !important;
  width: 5rem !important;
  line-height: 6.5rem !important;

}
}


</style>