<template>
  <div class="view">
    <!-- 背景装饰图片 -->
    <div class="backgroundBox1">
      <img class="" src="../../../public/images/bg1.png" alt="" />
      <img src="../../../public/images/bg2.png" alt="" />
    </div>
    <img class="Vector1" src="../../../public/images/Vector 1.png" alt="" />
    <img class="numSell-bg" src="../../../public/images/Group 26.png" alt="" />
    <img class="bg3" src="../../../public/images/bg3.png" alt="" />

    <!-- 置顶按钮 -->
    <img  alt=""  @click="toTop()" src="../../../public/images/top.png" class="toTop" />

    <!-- 菜单栏 -->
    <div class="home-top">
      <img src="../../../public/images/TE.png" alt="" />

      <div class="home-top-right">
        <div class="menu">
          <el-menu class="el-menu-demo" mode="horizontal">
            <el-menu-item index="1">首页</el-menu-item>
            <el-menu-item @click="skipDownload()" index="2">下载与教程</el-menu-item>
            <el-menu-item @click="skipIntroduce()" index="3">软件介绍</el-menu-item>
            <el-menu-item @click="skipScenario()" index="4">使用场景</el-menu-item>
            <el-menu-item @click="skipNumSell()" index="5">靓号出售</el-menu-item>
            <el-menu-item @click="skipWel()" index="6">联系我们</el-menu-item>
          </el-menu>
        </div>

        <el-dropdown class="langSelect">
          <span class="el-dropdown-link"> 语言 </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>中文</el-dropdown-item>
            <el-dropdown-item>
              <router-link class="link" style="color: #fff" to="/T">繁体</router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link class="link" style="color: #fff" to="/E">English</router-link>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="home-log">
        <img src="../../../public/images/top-logo-1.png" alt="" />
        <img src="../../../public/images/top-logo-2.png" alt="" />
      </div>
    </div>

    <!-- 标语栏 -->
    <img class="top-title" src="../../../public/images/TE靓号生成软件 800x600 1.png"  alt="" />

    <ul class="slogan">
      <li>个性靓号 与众不同 不可替代</li>
      <li>生意靠广告 广告靠靓号</li>
      <li>豹子靓号 私人专属 彰显尊贵</li>
      <li>钱包便捷多签 资金绝对安全</li>
    </ul>

    <!-- 下载与教程 -->
    <div class="download">
      <h2 class="title downloadSkip">软件 <span>下载与教程</span></h2>

      <div class="download-bg2">使用教程</div>

      <div class="download-content">
        <div class="download-content-left">
          <p class="download-content-1">
            本软件只适用于windows 7以上版本的系统，
            靓号生成功能属收费功能，下载后免费试用12
            小时，激活码识别机器码，一个激活码只适用
            一台电脑，更换电脑时可以联系客服解绑激活
            码，重新绑定。Tron钱包多签功能属长期免费。
          </p>
          <div class="downloadFoot">
            <button @click="downloadARJ()" class="download-button">下载</button>
            <p class="downLink">
              下载地址：
              <a href="https://tetoken.vip/down/TE靓号v1.1.8.rar" download="TE靓号v1.1.8.rar"
                target="_blank">https://tetoken.vip/down/TE靓号v1.1.8.rar</a>
            </p>
          </div>
        </div>
      </div>

      <div class="download-video v-bg" v-if="play1" ><img @click="playPart1" src="../../../public/images/play.png" alt=""> </div>
      <video v-else class="download-video" autoplay src="https://tetoken.vip/使用教程.mp4" type="video/mp4" controls="controls"
        control="control" loop="-1"></video>
    </div>

    <!-- 软件介绍 -->
    <div class="introduce">
      <h2 class="title introduceSkip">软件 <span>介紹</span></h2>
      <img class="introduce-bg1" src="../../../public/images/Group 45.png" alt="" />
      <div class="introduce-bg2"></div>
      <img class="introduce-bg3" src="../../../public/images/introduce-bg2.png" alt="" />

      <div class="introduce-content">
        <div class="introduce-content-left">
          <h3>Tron和ETH钱包地址靓号生成</h3>
          <p>
            本软件可用于自动生成波场和以太坊的钱包地址，
            并从中筛选出适合需要的靓号和个性地址。无需多
            开、自适应本地电脑的运算能力；可筛选豹子尾数 如:8888；可筛选个性尾数
            如:SSiJ；可前后端 一起筛选，配置时用*隔开 如:TG*8888;运算速度
            受本地电脑的配置影响，也因筛选的位数有关，一
            般前后总的4-6位较快，7位以上可能要等数天。
          </p>
          <h3>Tron钱包多签、转账功能</h3>
          <p>
            加密货币网络上的标准交易可称为单签名交易，因为它们只
            需要一个数字签名即可完成交易。而多重签名是每个权限可
            以对应多个私钥，要求交易签名在执行之前必须达到定制的
            权重。TE的TRON的多重签名简化了操作流程，用户在创建
            一个多重签名权限组时，可将地址添加至该权限组，软件自
            动为每个地址设置权重，并为该权限组设置阈值。在进行相
            关权限的交易签名时，自动为该权限组添加的地址配置签名
            权重之和达到阈值，则可以执行交易。操作简单易用，绝对 安全可靠！
          </p>
        </div>

        <div class="introduce-content-right">
          <h3>软件介绍视频</h3>
          <div class="introduce-video v-bg" v-if="play2" ><img @click="playPart2" src="../../../public/images/play.png" alt=""> </div>
          <video class="introduce-video" v-else autoplay src="https://tetoken.vip/软件介绍.mp4" type="video/mp4" controls="controls"
            control="control" loop="-1"></video>

          <p>地址：</p>
          <p>TJuzziZto3MSt3Ytfk7m66ciZDgVHQ888888</p>
          <p>私钥：</p>
          <p>
            9943cb2c379da286911203462aa9813fd07d607c000815ad30487b07285e8fc9
          </p>
          <p>转走了钱是您的！</p>
        </div>
      </div>
    </div>

    <!-- 使用场景 -->
    <div class="scenario">
      <h2 class="title scenarioSkip">使用场景</h2>

      <div class="scenario-content">
        <div class="scenario-content-1">
          <div>
            <h3>豹子靓号 私人专属 彰显尊贵， 用于平台收款钱包，彰显实力！</h3>
            <p>如：</p>
            <p>TTUmoFHmtqKAXKHomRQRCdN4sexT<span>EEEEEE</span></p>
          </div>
          <img src="../../../public/images/Group 28.png" alt="" />
        </div>

        <div class="scenario-content-1">
          <div>
            <h3>个性靓号 与众不同 不可替代， 用于平台多个钱包，整齐规范！</h3>
            <p>如：</p>
            <p><span>TN5</span>8z1T82jB2VTaAXuBMMPLw1MmAr<span>F2tVV</span></p>
            <p><span>TN5</span>sXaQ6WuNGDS276XTt6VtEbZeAm<span>F2tVV</span></p>
          </div>
          <img src="../../../public/images/Group 29.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 靓号出售 -->
    <div class="numSell">

      <h2 class="title numSellSkip"><span>软件 · 靓号</span> 出售</h2>
      <div class="numSearch">
        <div class="numSearch-bg"></div>
        <input class="search" v-model="numSearch" placeholder="搜索靓号" @keyup="enterSearch" type="text" />
        <i class="el-icon-search search-button" @click="searchNum()"></i>
      </div>

      <div class="numSell-content">
        <div class="numSell-content-left">
          <h3>TRC20靓号钱包地址价格</h3>
          <p>四位：<span>4.9U</span> 如：TGemo……TEE8888</p>
          <p>五位：<span>20.9U</span> 如：TGemo……TE88888</p>
          <p>六位：<span>29.9U</span> 如：TGemo……T888888</p>
          <h3 class="numSell-title-2">软件价格</h3>
          <p>零售：T-E钱包生成软件单价： <span>288U</span></p>
          <p>团购：一次性购买2-5个，单价： <span>198U</span></p>
          <p>代理：一次性购买5个以上，单价： <span>128U</span> （可寄存）</p>
        </div>

        <div class="numSell-content-left">
          <h3>ERC20靓号钱包地址价格</h3>
          <p>五位：<span>4.9U</span> 如：0xB0f……De288888</p>
          <p>六位：<span>20.9U</span> 如：0xB0f……De888888</p>
          <p>七位：<span>29.9U</span> 如：0xB0f……D8888888</p>
          <img src="../../../public/images/image 1.png" alt="" />
        </div>
        <p class="collectionCode">
          收款钱包：TTUmoFHmtqKAXKHomRQRCdN4sexTEEEEEE
          <el-tooltip class="item" effect="dark" content="单击复制" placement="top">
            <img  alt=""  @click="copy()" src="../../../public/images/copy.png" />
          </el-tooltip>
        </p>
      </div>
    </div>

    <!-- 安全操作警告 -->
    <div class="safe">
      <img src="../../../public/images/Group 20.png" alt="" />

      <div class="safe-title">
        <img src="../../../public/images/Frame.png" alt="" />
        <h3>安全警示 使用软件应注意的安全操作警示</h3>
      </div>
      <p>
        本软件的Tron多签技术采用多层加密技术，多签地址私钥多
        层加密存储在本地，离开了本地电脑和缺少您设置的密码是
        无法解密私钥，保证钱包私钥的安全性。建议使用多签的TE
        软件采用干净的windows10以上的正版系统，防止盗版系统
        存在木马，防止有剪切板挟持等情况，复制地址和私钥时，
        请认真确认是否正确。本软件只有多签转账功能，没有授权
        合约的功能，自然不会被利用于合约授权盗取钱包资产。
      </p>

      <p>
        参考教程：<a href="https://t.me/Wallet_Safety" target="_blank">https://t.me/Wallet_Safety</a>
      </p>
    </div>

    <!-- 页脚 -->
    <div class="foot">
      <div class="foot-contact">
        <img src="../../../public/images/foot.png" alt="" />
        <div>
          <h3>欢迎联系</h3>
          <h3>
            可用
            <a href="https://t.me/TEservice/" target="_blank">Telegram</a>、
            <a href="https://songge8804@gmail.com/" target="_blank">Facebook</a>、
            <a href="https://api.whatsapp.com/send?phone=+14452987497&text=Hello/" target="_blank">Washapp</a>、
            <a href="https://songge8804@gmail.com/" target="_blank">邮箱</a>
            等联系方式
          </h3>
        </div>
      </div>
      <div class="foot-more">
        <img src="../../../public/images/TE.png" alt="" />
        <p>更多详情 请联系 <span>TE工作室</span></p>
      </div>
    </div>

    <!-- 检索靓号弹窗 -->
    <el-dialog class="searchMo" :visible.sync="searchVisible" top="10vh" :before-close="handleClose" :show-close="false">
      <div class="searchDialog">
        <h3 v-if="isTRC">TRC20 靓号地址</h3>
        <h3 v-else>ERC20 靓号地址</h3>
        <img @click="handleClose" src="../../../public/images/close.png" alt="" />
        <div class="searchData">
          <template v-for="item in data">
            <a v-if="isTRC" :key="item.id" :href="'https://tronscan.io/#/address/' + item.address" target="_blank">{{
              item.address }}</a>
            <a v-else :key="item.id + 'E'" :href="'https://cn.etherscan.com/address/' + item.address" target="_blank">{{
              item.address }}</a>
          </template>
        </div>
        <el-pagination :page-size="12" :current-page.sync="currentPage" class="tableFoot" layout="prev, pager, next" :total="total"
          @current-change="handleCurrentChange">
        </el-pagination>
        <button v-if="isTRC" @click="changeERC()" class="change">切换为ERC20 靓号地址</button>
        <button v-else @click="changeTRC()" class="change">切换为TRC20 靓号地址</button>
      </div>
    </el-dialog>

  </div>
</template>

<script>

export default {
  data() {
    return {
      numSearch: "",
      searchVisible: false,
      data: [],
      currentPage: 1, //当前页
      total: 0,
      isTRC: true,
      play1:true,
      play2:true,
    };
  },
  computed: {
    searchData() {
      return this.data.slice((this.currentPage - 1) * 12 + 1, this.currentPage * 12 + 1)
    },
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    },
    downloadARJ() {
      window.open("https://tetoken.vip/down/TE靓号v1.1.8.rar");
    },
    copy() {
      let url = "TTUmoFHmtqKAXKHomRQRCdN4sexTEEEEEE";
      //新建一个文本框
      let oInput = document.createElement("input");
      //赋值给文本框
      oInput.value = url;
      document.body.appendChild(oInput);
      // 选择对象;
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      //复制完成删除掉输入框
      oInput.remove();
      this.$message({
        message: "复制成功",
        type: "success",
        duration: 1500
      });
    },
    skipDownload() {
      document.querySelector(".download").scrollIntoView(true);
    },
    skipIntroduce() {
      document.querySelector(".introduceSkip").scrollIntoView(true);
    },
    skipScenario() {
      document.querySelector(".scenarioSkip").scrollIntoView(true);
    },
    skipNumSell() {
      document.querySelector(".numSellSkip").scrollIntoView(true);
    },
    skipWel() {
      document.querySelector(".foot").scrollIntoView(true);
    },
    toTop() {
      document.querySelector(".home-top").scrollIntoView(true);
    },
    // 搜索靓号事件
    searchNum() {
      if (this.numSearch == "") {
        this.$message("请先输入靓号");
      } else {
        // console.log({ address: this.numSearch,pageSize:12,currentPage:this.currentPage });
        this.getData({ address: this.numSearch,size:12,current:this.currentPage,status:1 })
      }
    },
    // 输入框回车事件
    enterSearch(e) {
      if (e.keyCode == 13) {
        this.searchNum();
      }
    },
    // 分页器翻页事件
    handleClose() {
      this.searchVisible = false;
      this.numSearch = "";
      this.data = [];
      this.currentPage = 1
      this.isTRC = true
    },
    playPart1(){
      this.play1 = false
    },
    playPart2(){
      this.play2 = false
    },
    // 翻页操作
    handleCurrentChange(val) {
      this.currentPage = val;
      if (this.isTRC) {
        this.getData({ address: this.numSearch,size:12,current:this.currentPage,status:1 })
      }else{
        this.getErcData({ address: this.numSearch,pageSize:12,currentPage:this.currentPage })
      }
    },
    // 获取搜索靓号数据
    async getData(data) {
      // console.log(data);
      const res = await this.$api.numberApi.post(data)
      if (res.data.total == 0) {
        this.isTRC = true
        this.searchVisible = true;
        this.$message.error('TRC20靓号未查询到相关靓号，请确认后重新查询')
      } else {
        // console.log(res);
        this.isTRC = true
        this.total = res.data.total
        this.data = res.data.records;
        this.searchVisible = true;
      }

    },
    // 获取搜索靓号数据
    async getErcData(data) {
      const res = await this.$api.numberApi.postE(data)
      if (res.records.length == 0) {
        this.$message.error('ERC20靓号未查询到相关靓号，请确认后重新查询')
      } else {
        this.total = res.total;
        this.data = res.records;
        this.searchVisible = true;
        this.isTRC = false
      }

    },
    // 切换ERC20列表
    changeERC(){
      this.currentPage = 1
      this.getErcData({ address: this.numSearch,pageSize:12,currentPage:this.currentPage })
    },
    // 切换TRC20列表
    changeTRC(){
      this.currentPage = 1
      this.getData({ address: this.numSearch,size:12,current:this.currentPage,status:1 })
    },
  },
};
</script>


<style lang="less" scoped >
@import "../../style/home.less";
</style>

<style  >
.el-pager li,
.el-pagination button:disabled,
.el-pagination .btn-next,
.el-pagination .btn-prev {
  background: #5958d8 !important;
  margin-right: .5rem !important;
  color: #fff !important;
  border-radius: .3125rem !important;
  min-width: 2.25rem !important;
  height: 2.25rem !important;
  font-size: 1.25rem !important;
  line-height: 2.25rem !important;
}

.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
  font-size: 1.25rem !important;
}

.el-pager li.active {
  color: #409EFF !important;
}

.el-dialog {
  background: none !important;
  box-shadow: none !important;
}
</style>