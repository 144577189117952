import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home.vue'
import Tradition from '../views/home/TraditionalStyle.vue'
import English from '../views/home/English.vue'
import declaration from '../views/declaration.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/T',
    name: 'Tradition',
    component: Tradition
  },
  {
    path: '/E',
    name: 'English',
    component: English
  },
  {
    path: '/declaration',
    name: 'declaration',
    component: declaration
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
