<template>
  <div class="view">
    <!-- <img class="view-bg" src="../../../public/images/Group 52-E.png" alt="" /> -->

    <!-- 背景装饰图片 -->
    <div class="backgroundBox1">
      <img class="" src="../../../public/images/bg1.png" alt="" />
      <img src="../../../public/images/bg2.png" alt="" />
    </div>
    <img class="Vector1" src="../../../public/images/Vector 1.png" alt="" />
    <img class="numSell-bg" src="../../../public/images/Group 26-E.png" alt="" />
    <img class="bg3" src="../../../public/images/bg3.png" alt="" />

    <!-- 置顶按钮 -->
    <img @click="toTop()" src="../../../public/images/top.png" class="toTop"  alt=""  />

    <!-- 菜单栏 -->
    <div class="home-top">
      <img src="../../../public/images/TE.png" alt="" />

      <div class="home-top-right">
        <div class="menu">
          <el-menu class="el-menu-demo" mode="horizontal">
            <el-menu-item index="1">FRNT PAGEZZ</el-menu-item>
            <el-menu-item @click="skipDownload()" index="2">
              <p>DOWNLOADS</p>
              <p>AND TUTORIALS</p>
            </el-menu-item>
            <el-menu-item @click="skipIntroduce()" index="3">
              <p>SOFTWARE</p>
              <p>INTRODUCTION</p>
            </el-menu-item>
            <el-menu-item @click="skipScenario()" index="4">
              <p>SCENES TO</p>
              <p>BE USED</p>
            </el-menu-item>
            <el-menu-item @click="skipNumSell()" index="5">
              <p>FOR SALE</p>
            </el-menu-item>
            <el-menu-item @click="skipWel()" index="6">CONTACT US</el-menu-item>
          </el-menu>
        </div>

        <el-dropdown class="langSelect">
          <span class="el-dropdown-link">LANGUAGE</span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <router-link class="link" style="color: #fff" to="/">中文</router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link class="link" style="color: #fff" to="/T">繁体</router-link>
            </el-dropdown-item>
            <el-dropdown-item>English</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="home-log">
        <img src="../../../public/images/top-logo-1.png" alt="" />
        <img src="../../../public/images/top-logo-2.png" alt="" />
      </div>
    </div>

    <!-- 标语栏 -->
    <img class="top-title" src="../../../public/images/Group 57@2x.png"  alt="" />

    <ul class="slogan">
      <li>Personalized pretty number is different and irreplaceable</li>
      <li>
        Business depends on advertising, advertising depends on good numbers
      </li>
      <li>
        Leopard's beautiful number is private and exclusive, showing dignity
      </li>
      <li>
        The wallet is convenient and multi-signature, and the funds are
        absolutely safe
      </li>
    </ul>

    <!-- 下载与教程 -->
    <div class="download">
      <h2 class="title downloadSkip">
        TE WALLET <span>DOWNLOADS AND TUTORIALS</span>
      </h2>

      <div class="download-bg2">TUTORIAL</div>

      <div class="download-content">
        <div class="download-content-left">
          <p>
            This software is only applicable to Windows 7 and above systems. The
            function of generating good numbers is a paid function, and it can
            be tried for free after downloading 12 hours, the activation code
            identifies the machine code, and one activation code is only
            applicable One computer, you can contact customer service to unbind
            and activate when replacing the computer code and rebind. The
            multi-signature function of the Tron wallet is free for a long time.
          </p>
          <button @click="downloadARJ()" class="download-button">
            Download
          </button>
          <p class="downLink">
            Download link：
            <a href="https://tetoken.vip/down/TE靓号v1.1.8.rar" download="TE靓号v1.1.8.rar"
              target="_blank">https://tetoken.vip/down/TE靓号v1.1.8.rar</a>
          </p>
        </div>
      </div>

      <div class="download-video v-bg" v-if="play1" ><img @click="playPart1" src="../../../public/images/play.png" alt=""> </div>
      <video v-else class="download-video" autoplay src="https://tetoken.vip/使用教程-En.mp4" type="video/mp4" controls="controls"
        control="control" loop="-1"></video>
    </div>

    <!-- 软件介绍 -->
    <div class="introduce">
      <h2 class="title introduceSkip">SOFTWARE <span>INTRODUCE</span></h2>
      <img class="introduce-bg1" src="../../../public/images/Group 45-E.png" alt="" />
      <div class="introduce-bg2"></div>
      <img class="introduce-bg3" src="../../../public/images/introduce-bg2.png" alt="" />

      <div class="introduce-content">
        <div class="introduce-content-left">
          <h3>Generating Tron and ETH Wallet Addresses and Pretty Numbers</h3>
          <p>
            This software can be used to automatically generate TRON and
            Ethereum wallet addresses, And screen out the good numbers and
            personalized addresses that suit your needs. no need Open adapt to
            the computing power of the local computer; can filter the leopard
            mantissa Such as: 8888; can filter personality mantiss such as:
            SSiJ; front and rear ends Filter together, separate with when
            configuring, such as: TG*8888; computing speed Affected by the
            configuration of the local computer, it is also related to the
            number of digits screened. Generally, the total number of 4-6 places
            before and after is faster, and it may take several days to wait for
            more than 7 places.
          </p>
          <h3>Tron wallet multi-signature, transfer function</h3>
          <p class="introduce-content-left-2">
            Standard transactions on cryptocurrency networks can be called
            single-signature transactions because they only A digital signature
            is required to complete the transaction. And multi-signature is that
            each authority can To correspond to multiple private keys, it is
            required that the transaction signature must reach the customized
            Weights. TE's TRON multi-signature simplifies the operation process,
            and users create When creating a multi-signature authority group,
            the address can be added to the authority group, and the software
            automatically Automatically set weights for each address and set
            thresholds for that permission group. in progress When signing
            transactions with permissions off, automatically configure
            signatures for addresses added to the permission group When the sum
            of the weights reaches the threshold, the transaction can be
            executed. Easy to use, absolutely Safe and reliable!
          </p>
          <p class="introduce-content-3">Address：</p>
          <p class="introduce-content-4">
            TJuzziZto3MSt3Ytfk7m66ciZDgVHQ888888
          </p>
          <p class="introduce-content-5">private key：</p>
          <p class="introduce-content-6">
            9943cb2c379da286911203462aa9813fd07d607c000815ad30487b07285e8fc9
          </p>
        </div>

        <div class="introduce-content-right">
          <h3>SOFTWARE INTRODUCTION VIDEO</h3>
          <div class="introduce-video v-bg" v-if="play2" ><img @click="playPart2" src="../../../public/images/play.png" alt=""> </div>
          <video class="introduce-video" v-else autoplay src="https://tetoken.vip/软件介绍-En.mp4" type="video/mp4" controls="controls"
            control="control" loop="-1"></video>
        </div>
      </div>
    </div>

    <!-- 使用场景 -->
    <div class="scenario">
      <h2 class="title scenarioSkip">SCENES TO BE USED</h2>

      <div class="scenario-content">
        <div class="scenario-content-1">
          <div>
            <h3>
              Leopard's beautiful number is private and exclusive, showing
              dignity, Used for platform collection wallets to demonstrate
              strength！
            </h3>
            <p>like：</p>
            <p>TTUmoFHmtqKAXKHomRQRCdN4sexT<span>EEEEEE</span></p>
          </div>
          <img src="../../../public/images/scenario-E-1.png" alt="" />
        </div>

        <div class="scenario-content-1">
          <div>
            <h3>
              Personalized pretty number is different and irreplaceable, Used
              for multiple wallets on the platform, neat and standardized！
            </h3>
            <p>like：</p>
            <p><span>TN5</span>8z1T82jB2VTaAXuBMMPLw1MmAr<span>F2tVV</span></p>
            <p><span>TN5</span>sXaQ6WuNGDS276XTt6VtEbZeAm<span>F2tVV</span></p>
          </div>
          <img src="../../../public/images/scenario-E-2.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 靓号出售 -->
    <div class="numSell">
      <h2 class="title numSellSkip"><span>SOFTWARE · LIANG </span>SELL</h2>
      <div class="numSearch">
        <div class="numSearch-bg"></div>
        <input class="search" v-model="numSearch" placeholder="Search for a good number" @keyup="enterSearch"  type="text" />
        <i class="el-icon-search search-button" @click="searchNum()"></i>
      </div>

      <div class="numSell-content">
        <div class="numSell-content-left-1">
          <h3>TRC20 wallet address price</h3>
          <p>Four<span>4.9U</span> Like：TGemo……TEE8888</p>
          <p>Five：<span>20.9U</span> Like：TGemo……TE88888</p>
          <p>Six：<span>29.9U</span> Like：TGemo……T888888</p>
          <h3 class="numSell-title-2">Software price</h3>
          <p>
            Retail: T-E wallet generation software unit price：
            <span>288U</span>
          </p>
          <p>
            Group purchase: buy 2-5 at a time, unit price： <span>198U</span>
          </p>
          <p>
            Agent: Buy more than 5 at a time, unit price：
            <span>128U</span> （Can be stored）
          </p>
        </div>

        <div class="numSell-content-left-2">
          <h3>ERC20 wallet address price</h3>
          <p>Five：<span>4.9U</span> Like：0xB0f……De288888</p>
          <p>Six：<span>20.9U</span> Like：0xB0f……De888888</p>
          <p>Seven：<span>29.9U</span> Like：0xB0f……D8888888</p>
          <img src="../../../public/images/image 1.png" alt="" />
        </div>
        <p class="collectionCode-1">Receiving wallet：</p>
        <p class="collectionCode">
          TTUmoFHmtqKAXKHomRQRCdN4sexTEEEEEE
          <el-tooltip class="item" effect="dark" content="copy" placement="top">
            <img @click="copy()" src="../../../public/images/copy.png"  alt="" />
          </el-tooltip>
        </p>
      </div>
    </div>

    <!-- 安全操作警告 -->
    <div class="safe">
      <img src="../../../public/images/aeneb.png" alt="" />

      <div class="safe-title">
        <img src="../../../public/images/Frame.png" alt="" />
        <h3>
          Safety Warning Safety operation warnings that should be paid attention
          to when using the software
        </h3>
      </div>
      <p class="safe-content">
        The Tron multi-signature technology of this software adopts multi-layer
        encryption technology, and the multi-signature address has multiple
        private keys. Layer encryption is stored locally, leaving the local
        computer and missing the password you set is The private key cannot be
        decrypted to ensure the security of the wallet private key. It is
        recommended to use multi-signature TE The software adopts a clean
        Windows 10 or above genuine system to prevent piracy There are Trojan
        horses to prevent clipboard hijacking, etc. When copying addresses and
        private keys, Please check carefully whether it is correct. This
        software only has the function of multi-signature transfer without
        authorization The function of the contract will naturally not be used
        for contract authorization to steal wallet assets.
      </p>

      <p class="safe-link">
        Reference tutorial：<a href="https://t.me/Wallet_Safety" target="_blank">https://t.me/Wallet_Safety</a>
      </p>
    </div>

    <!-- 页脚 -->
    <div class="foot">
      <div class="foot-contact">
        <img src="../../../public/images/foot.png" alt="" />
        <div>
          <h3>welcome to contact</h3>
          <h3>
            Available <a href="https://t.me/TEservice/" target="_blank">Telegram</a>、
            <a href="https://songge8804@gmail.com/" target="_blank">Facebook</a>、
            <a href="https://api.whatsapp.com/send?phone=+14452987497&text=Hello/" target="_blank">Washapp</a>、 <a
              href="https://songge8804@gmail.com/" target="_blank">Email</a> and other
            contact information
          </h3>
        </div>
      </div>
      <div class="foot-more">
        <img src="../../../public/images/TE.png" alt="" />
        <p>For more details please contact <span>TE studio</span></p>
      </div>
    </div>

    <!-- 检索靓号弹窗 -->
    <el-dialog class="searchMo" :visible.sync="searchVisible" top="10vh" :before-close="handleClose" :show-close="false">
      <div class="searchDialog">
        <h3 v-if="isTRC">TRC20 PRETTY NUMBER ADDRESS</h3>
        <h3 v-else>ERC20 PRETTY NUMBER ADDRESS</h3>
        <img @click="handleClose" src="../../../public/images/close.png" alt="" />
        <div class="searchData">
          <template v-for="item in data">
            <a v-if="isTRC"  :key="item.id" :href="'https://tronscan.io/#/address/' + item.address" target="_blank">{{ item.address }}</a>
            <a v-else  :key="item.id + 'E'" :href="'https://cn.etherscan.com/address/' + item.address" target="_blank">{{ item.address }}</a>
          </template>
        </div>
        <el-pagination :page-size="12" class="tableFoot" layout="prev, pager, next" :total="total"
          @current-change="handleCurrentChange">
        </el-pagination>
        <button v-if="isTRC" @click="() => getErcData({ address: this.numSearch })" class="change">Change to ERC20 number address</button>
        <button v-else @click="() => getData({ address: this.numSearch })" class="change">Change to TRC20 number address</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  data() {
    return {
      numSearch: "",
      searchVisible: false,
      data: [],
      currentPage: 1, //当前页
      total: 0,
      isTRC:true,
      play1:true,
      play2:true,
    };
  },
  computed: {
    searchData() {
      return this.data.slice((this.currentPage - 1) * 12 + 1, this.currentPage * 12 + 1)
    }
  },
  methods: {
    handleSelect(key, keyPath) { },
    onSubmit() {
      console.log("submit!");
    },
    downloadARJ() {
      window.open("https://tetoken.vip/down/TE靓号v1.1.8.rar");
    },
    copy() {
      let url = "TTUmoFHmtqKAXKHomRQRCdN4sexTEEEEEE";
      //新建一个文本框
      let oInput = document.createElement("input");
      //赋值给文本框
      oInput.value = url;
      document.body.appendChild(oInput);
      // 选择对象;
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      //复制完成删除掉输入框
      oInput.remove();
      this.$message({
        message: "Successful replication",
        type: "success",
        duration: 1500
      });
    },
    skipDownload() {
      document.querySelector(".download").scrollIntoView(true);
    },
    skipIntroduce() {
      document.querySelector(".introduceSkip").scrollIntoView(true);
    },
    skipScenario() {
      document.querySelector(".scenarioSkip").scrollIntoView(true);
    },
    skipNumSell() {
      document.querySelector(".numSellSkip").scrollIntoView(true);
    },
    skipWel() {
      document.querySelector(".foot").scrollIntoView(true);
    },
    toTop() {
      document.querySelector(".home-top").scrollIntoView(true);
    },

    // 搜索靓号事件
    searchNum() {
      if (this.numSearch == "") {
        this.$message("Please enter the number first");
      } else {
        this.getData({ address: this.numSearch,size:12,current:this.currentPage,status:1 })
      }
    },
    // 输入框回车事件
    enterSearch(e) {
      if (e.keyCode == 13) {
        this.searchNum();
      }
    },
    handleClose() {
      this.searchVisible = false;
      this.numSearch = "";
      this.data = [];
      this.currentPage = 1
    },
    playPart1(){
      this.play1 = false
    },
    playPart2(){
      this.play2 = false
    },
    // 翻页操作
    handleCurrentChange(val) {
      this.currentPage = val;
      if (this.isTRC) {
        this.getData({ address: this.numSearch,size:12,current:this.currentPage,status:1 })
      }else{
        this.getErcData({ address: this.numSearch,pageSize:12,currentPage:this.currentPage })
      }
    },
    // 获取搜索靓号数据
    async getData(data) {
      // console.log(data);
      const res = await this.$api.numberApi.post(data)
      if (res.data.total == 0) {
        this.isTRC = true
        this.searchVisible = true;
        this.$message.error('No corresponding smart number is found, please confirm and query again')
      } else {
        // console.log(res);
        this.isTRC = true
        this.total = res.data.total
        this.data = res.data.records;
        this.searchVisible = true;
      }

    },
    // 获取搜索靓号数据
    async getErcData(data) {
      const res = await this.$api.numberApi.postE(data)
      if (res.records.length == 0) {
        this.$message.error('No corresponding smart number is found, please confirm and query again')
      } else {
        this.total = res.total;
        this.data = res.records;
        this.searchVisible = true;
        this.isTRC = false
      }

    },
    // 切换ERC20列表
    changeERC(){
      this.currentPage = 1
      this.getErcData({ address: this.numSearch,pageSize:12,currentPage:this.currentPage })
    },
    // 切换TRC20列表
    changeTRC(){
      this.currentPage = 1
      this.getData({ address: this.numSearch,size:12,current:this.currentPage,status:1 })
    },
  },
};
</script>


<style lang="less" scoped >
@import "../../style/home-e.less";
</style>    

<style  >

.el-pager li,
.el-pagination button:disabled,
.el-pagination .btn-next,
.el-pagination .btn-prev{
  background: #5958d8 !important;
  margin-right: .5rem !important;
  color: #fff !important;
  border-radius: .3125rem !important;
  min-width: 2.25rem !important;
  height: 2.25rem !important;
  font-size: 1.25rem !important;
  line-height: 2.25rem !important;
}


.el-pager li.active{
  color: #409EFF !important;
}

.el-dialog{
  background: none !important;
  box-shadow: none !important;
}
</style>