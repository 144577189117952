import axios from "axios";

const numberApi = {
  post(params){
    return axios({
      url:'/tweak/good-address/getWalletAddressList',
      method:'POST',
      data:params
    })
  },
  postE(params){
    return axios({
      url:'/te/getEthreumAddressList',
      method:'POST',
      data:params
    })
  },

}

export default numberApi;