<template>
  <div id="app">
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>

export default {
  metaInfo() {
    return {
      title: '波场靓号钱包地址，以太坊靓号尾数钱包地址生成软件',
      meta: [
        {
          name: "keyword",
          content: "多签钱包,区块链,以太坊波场,智能多签转账,波場合约转账,usdt钱包,钱包地址靓号生成,tron链上工具,trx地址靓号生成,波場钱包,以太坊钱包"
        },
        {
          name: "description",
          content: "TE软件可支持波场钱包地址，以太坊钱包地址靓号快速生成，每秒大概生成10000个地址，全程可离线操作，安全可靠。软件还支持波场智能合约多签转账，为钱包上一层保护锁"
        },

      ],
    }
  }
}


</script>

<style lang="less">
@import './style/common.less';
</style>
