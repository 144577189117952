function adapter() {
    let dol = document.documentElement;
    let _width = dol.clientWidth;
    dol.style.fontSize = _width / (1920 / 16) + "px";
}

window.onload = function () {
    adapter();
};

window.onresize = function () {
    adapter();
};
